<script>
import {addQuestionApi, updateQuestionApi} from "@/www/urls/driverLearning";
import {getCityData} from '@/urls/index';
import ImageUpload from "@/components/imageUpload/index.vue";

const item = {
    id: +new Date(),
    checked: false,
    content: ''
}
const initForm = {
    "questionAnswer": undefined, //答案
    "questionInfo": [{...item, id: +new Date()}, {...item, id: +new Date()}], //问题详情
    "questionPhotoUrl": [],
    "questionName": undefined,// 问题名称
    "questionTitle": undefined,// 问题抬头
    "questionType": '1' //0     1单选  2多选
}
export default {
    name: "edit-dialog",
    components: {ImageUpload},
    data() {
        return {
            cityList: [],
            letters: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M'],
            visible: false,
            loading: false,
            form: {...initForm},
            rules: {
                questionTitle: {required: true, message: '请输入题目', trigger: 'change'},
                questionName: {required: true, message: '请输入来源', trigger: 'change'},
                questionInfo: {
                    validator: (rule, value, cb) => {
                        console.log(this.form.questionType);
                        if (value.length < 2 && this.form.questionType === '1')
                            return cb('请至少设置两个答案选项')
                        if (value.length < 2 && this.form.questionType === '2')
                            return cb('请至少设置三个答案选项')
                        const list = value.filter(({checked}) => checked)
                        if (list.length === 0 && this.form.questionType === '1')
                            return cb('请至少选择一个正确答案')
                        if (list.length < 2 && this.form.questionType === '2')
                            return cb('请至少选择两个正确答案')
                        cb();
                    },
                    trigger: 'change'
                }
            }
        }
    },
    mounted() {
        this.getServerCity();
    },
    methods: {
        open(record = {}) {
            this.visible = true;
            this.form = {
                ...initForm,
                ...record,
                questionPhotoUrl: record.questionPhotoUrl ? [{
                    imageUrl: record.questionPhotoUrl,
                    showImage: true
                }] : []
            }
        },
        handleAddItem() {
            this.form.questionInfo.push({...item, id: +new Date()})
        },
        handleDelItem(index) {
            this.form.questionInfo.splice(index, 1)
        },
        async handleSave() {
            try {
                await this.$refs['form'].validate();
                this.loading = true;
                const fn = this.form.id ? updateQuestionApi : addQuestionApi;
                const ret = await fn({
                    ...this.form,
                    questionInfo: JSON.stringify(this.form.questionInfo),
                    questionAnswer: this.form.questionInfo.map((item, index) => ({
                        ...item,
                        index
                    })).filter(({checked}) => checked).map(({index}) => index).join(','),
                    questionPhotoUrl: this.form.questionPhotoUrl && this.form.questionPhotoUrl.length ? this.form.questionPhotoUrl[0].imageUrl : ''
                })
                if (ret.success && ret.data) {
                    this.$message.success('保存成功');
                    this.visible = false;
                    this.$emit('success', this.form)
                } else this.$message.error(ret.errors[0].message)
            } finally {
                this.loading = false
            }
        },
        handleItemCheckChange(index) {
            if (this.form.questionType === '1') {
                this.form.questionInfo = this.form.questionInfo.map((item, idx) => ({
                    ...item,
                    checked: index === idx ? item.checked : false
                }))
            }
        },
        async getServerCity() {
            try {
                let res = await getCityData(this, '2');
                if (res) {
                    this.cityList = res;
                } else
                    this.cityList = [];

            } catch (e) {
                console.log(e);
            }
        },
    }
}
</script>
<template>
    <el-dialog :visible.sync="visible" :title="(form.id?'编辑':'新建')+'题目'" top="30px">
        <div class="edit-dialog">
            <el-form ref="form" label-width="90px" :model="form" :rules="rules" hide-required-asterisk>
                <el-form-item label="题目类型：" prop="questionType">
                    <el-radio-group v-model="form.questionType">
                        <el-radio label="1">单选</el-radio>
                        <el-radio label="2">多选</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="题目图片：">
                    <div style="display:flex;align-items: center">
                        <ImageUpload :images="form.questionPhotoUrl" :constraint="{size:1024*1024}"
                                     @update:imageUploadCompleted="form.questionPhotoUrl=$event"></ImageUpload>
                        <span class="ml10 color-gary">图片要求：1MB以内</span>
                    </div>
                </el-form-item>
                <el-form-item label="题目：" prop="questionTitle">
                    <el-input type="textarea" show-word-limit style="width: 580px"
                              placeholder="请输入题目，最多可填入200个中文字"
                              :maxlength="200" v-model="form.questionTitle"></el-input>
                </el-form-item>
                <el-form-item label="来源：" prop="questionName">
                    <el-select filterable v-model="form.questionName" style="width: 100%" placeholder="请选择来源"
                               allow-create clearable>
                        <el-option v-for="vm in cityList" :key="vm.id" :value="vm.city+'交委'">
                            {{ vm.city + '交委' }}
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="答案选项：" prop="questionInfo">
                    <div class="mb10">
                        <el-button type="primary" size="small" @click="handleAddItem">+选项</el-button>
                        <span class="color-gary ml10">
                            {{ form.questionType === '1' ? '请勾选一个正确答案' : '至少勾选两个正确选项' }}
                        </span>
                    </div>
                    <el-form-item v-for="(vm,index) in form.questionInfo" :key="vm.id" label-width="70px"
                                  :prop="'questionInfo.'+index+'.content'"
                                  :rules="{required:true,message:'请输入答案内容'}">
                        <div slot="label" style="text-align: left;line-height: 1.5em">
                            <p style="margin-bottom: 0">选项{{ index + 1 }}</p>
                            <el-checkbox v-model="vm.checked" @change="handleItemCheckChange(index)">正确</el-checkbox>
                        </div>
                        <el-input show-word-limit type="textarea" style="width: 510px" v-model="vm.content"
                                  placeholder="请填写答案，最多可填入30个中文字"
                                  :maxlength="30"></el-input>
                        <el-link v-if="index>1" type="danger" class="ml10" :underline="false"
                                 @click="handleDelItem(index)">删除
                        </el-link>
                    </el-form-item>
                </el-form-item>
            </el-form>
        </div>
        <footer slot="footer">
            <el-button type="primary" plain @click="visible=false" :loading="loading">取消</el-button>
            <el-button type="primary" @click="handleSave" :loading="loading">保存</el-button>
        </footer>
    </el-dialog>
</template>

<style scoped lang="scss">
.edit-dialog {
    display: flex;

    &::v-deep {
        .el-form-item .el-form-item {
            margin-bottom: 22px;
        }

        .el-checkbox:not(.is-checked) {
            .el-checkbox__label {
                font-weight: normal;
                color: #999;
            }
        }

        .el-checkbox.is-checked {
            .el-checkbox__inner {
                background-color: #44B214;
                border-color: #44B214;
            }

            .el-checkbox__label {
                font-weight: normal;
                color: #44B214;
            }
        }
    }
}
</style>
