<script>
import {download, uploadExcel} from "@/common/js/Utils";
import {batchAddQuestionApi} from "@/www/urls/driverLearning";

const keys = {
    "题目类型": "questionType",
    "来源": "questionName",
    "题目": "questionTitle",
    "答案选项": "questionInfo",
    "答案": "questionAnswer"
}

export default {
    name: "ImportDialog",
    data() {
        return {
            loading: false,
            visible: false,
            letters: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M'],
            form: {
                list: []
            }
        }
    },
    methods: {
        open() {
            this.form.list = []
            this.visible = true
        },
        async handleImport() {
            try {
                await this.$refs['form'].validate()
                this.loading = true;
                const ret = await batchAddQuestionApi(this.form.list.map((item) => ({
                    ...item,
                    questionType: item.questionType === '单选' ? '1' : '2',
                    questionInfo: JSON.stringify(item.questionInfo.split(/\n/).filter((content) => (content || '').replace(/\s/g, '')).map((content, i) => ({
                        id: +new Date() + i,
                        content: content.replace(/\s/g, ''),
                        checked: (item.questionAnswer + '').split('、').includes((i + 1) + ''),
                    }))),
                    questionAnswer: (item.questionAnswer + '').split('、').map((item) => item - 1).join(',')
                })))
                if (!ret.success)
                    throw ret.errors[0].message
                this.$message.success("导入成功~")
                this.visible = false
                this.$emit('success')
            } catch (err) {
                this.$alert('请检查您的表单', '提示')
            } finally {
                this.loading = false
                this.$refs['upload'].clearFiles();
            }
        },
        handleDownload() {
            download('/tmpl/题目导入模版.xlsx', '题库导入模版')
        },
        async handleChange(file) {
            try {
                this.loading = true;
                this.loadingShow()
                const data = await uploadExcel(file.raw);
                let list = [], i = 0;
                for (let item of data) {
                    const it = {}
                    for (let key in item) {
                        if (keys[key]) {
                            it[keys[key]] = item[key]
                        }
                    }
                    it.id = +new Date() + i++
                    list.push(it)
                }
                this.form.list = list
            } catch (err) {
                this.$message.error(err.toString())
            } finally {
                this.loading = false
                this.loadingHide()
                this.$refs['upload'].clearFiles();
            }
        },
        questionTitleValidator(rule, value, cb) {
            if (!value)
                return cb('请输入题目')
            if ((value + '').length > 200)
                return cb('题目最多200个字')
            cb();
        },
        questionTypeValidator(rule, value, cb) {
            if (!value)
                return cb('请填写题目类型')
            if (!['单选', '多选'].includes(value))
                return cb('题目类型格式为【单选】或【多选】')
            cb()
        },
        questionInfoValidator(index, rule, value, cb) {
            if (!value)
                return cb('请填写答案选项')
            const list = (value + '').split('\n');
            if (this.form.list[index] === '多选' && list.length < 2)
                return cb('多选题请至少提供两个答案选项')
            cb()
        },
        questionAnswerValidator(index, rule, value, cb) {
            if (!value)
                return cb('请至少选择一个正确答案')
            const list = (value + '').split('、');
            if (this.form.list[index] === '多选' && list.length < 2)
                return cb('请至少选择2个正确答案，用【、】分割')
            if (this.form.list[index] === '单选' && list.length > 1)
                return cb('单选题只能选择一个答案')
            cb()
        },
        del(index) {
            this.form.list.splice(index, 1)
        }
    }
}
</script>

<template>
    <el-dialog :visible.sync="visible" title="导入题目" top="30px" width="1000px">
        <div>
            <el-upload :limit="1"
                       ref="upload"
                       style="display: inline-block"
                       action="https://900etrip.com"
                       :loading="loading"
                       :auto-upload="false"
                       accept=".xlsx"
                       :show-file-list="false" :on-change="handleChange">
                <el-button type="primary">导入</el-button>
            </el-upload>
            <el-button type="primary" class="ml10 " plain @click="handleDownload">模版下载</el-button>
        </div>
        <el-form ref="form" :model="form" v-if="form.list.length" size="small">
            <div class="import-dialog">
                <el-table :data="form.list" height="60vh">
                    <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
                    <el-table-column label="题目类型：" width="80">
                        <template slot-scope="scope">
                            <el-form-item :prop="'list.'+scope.$index+'.questionType'"
                                          :rules="{validator:questionTypeValidator}">
                                <el-input v-model="scope.row.questionType" placeholder="题目类型"></el-input>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="来源：" width="120">
                        <template slot-scope="scope">
                            <el-form-item :prop="'list.'+scope.$index+'.questionName'"
                                          :rules="{required:true,message:'请输入来源'}">
                                <el-input v-model="scope.row.questionName" placeholder="来源"></el-input>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="题目：">
                        <template slot-scope="scope">
                            <el-form-item :prop="'list.'+scope.$index+'.questionTitle'"
                                          :rules="{validator:questionTitleValidator}">
                                <el-input v-model="scope.row.questionTitle" placeholder="题目"></el-input>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="答案选项：">
                        <template slot-scope="scope">
                            <el-form-item :prop="'list.'+scope.$index+'.questionInfo'"
                                          :rules="{validator:questionInfoValidator.bind(null,scope.$index)}">
                                <el-input type="textarea" v-model="scope.row.questionInfo"
                                          placeholder="答案选项"></el-input>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="答案：" width="100">
                        <template slot-scope="scope">
                            <el-form-item :prop="'list.'+scope.$index+'.questionAnswer'"
                                          :rules="{validator:questionAnswerValidator.bind(null,scope.$index)}">
                                <el-input v-model="scope.row.questionAnswer" placeholder="题目类型"></el-input>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作：" width="60">
                        <template slot-scope="scope">
                            <el-link type="danger" :underline="false" @click="del(scope.$index)">删除</el-link>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-form>
        <div slot="footer" style="display: flex;justify-content: flex-end" v-if="form.list.length">
            <el-button type="primary" plain @click="visible=false">取消</el-button>
            <el-button type="primary" @click="handleImport">导入</el-button>
        </div>
    </el-dialog>
</template>

<style scoped lang="scss">
.import-dialog {
    &::v-deep {
        .el-table .cell {
            overflow: visible;
        }
    }
}
</style>
