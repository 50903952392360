<template>
    <div>
        <el-main class="com-eTrip-section section-has-footer" :class="'type-'+type">
            <SectionContent>
                <com-list-search>
                    <el-form :inline="true" size="medium" slot="list-search-before">
                        <el-form-item>
                            <el-select filterable v-model="form.questionName" style="width: 100%"
                                       placeholder="请选择来源"
                                       allow-create clearable>
                                <el-option v-for="vm in cityList" :key="vm.id" :value="vm.city+'交委'">
                                    {{ vm.city + '交委' }}
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-input placeholder="请输入题目" style="width: 240px" clearable
                                      v-model="form.questionTitle"/>
                        </el-form-item>
                    </el-form>
                    <div slot="list-search-after">
                        <el-button @click="resetClick()">重置</el-button>
                        <el-button type="primary" @click="getList()">搜索</el-button>
                        <el-button type="success" @click="handleAdd" v-if="!type">新增题目</el-button>
                        <el-button type="success" plain @click="handleImport" v-if="!type">导入题目</el-button>
                    </div>
                </com-list-search>
                <div>
                    <p style="padding: 10px" v-if="type">请在列表中选择需要的问题，最多可选100个</p>
                    <el-table ref="tableRef" style="width: 100%;" border v-loading="loading" :data="result.data"
                              row-key="id" @select="handleSelect">
                        <el-table-column type="selection" width="55" v-if="type" align="center"
                                         label="全选" :selectable="selectable"></el-table-column>
                        <el-table-column type="index" label="序号" width="80"></el-table-column>
                        <el-table-column prop="questionType" label="题型" width="120" v-if="!type">
                            <template slot-scope="scope">
                                {{ scope.row.questionType === '1' ? '单选' : '多选' }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="questionName" label="来源" width="120"></el-table-column>
                        <el-table-column prop="questionTitle" label="题目" width="300">
                            <template slot-scope="scope">
                                <el-image v-if="scope.row.questionPhotoUrl"
                                          :src="scope.row.questionPhotoUrl"
                                          style="width: 80px;"
                                          :preview-src-list="[scope.row.questionPhotoUrl]"></el-image>
                                <p>{{ scope.row.questionTitle }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column prop="questionInfo" label="答案">
                            <template slot-scope="scope">
                                <p v-for="(vm,index) in scope.row.questionInfo" :key="vm.id"
                                   :class="vm.checked?'color-success':''">
                                    {{ letters[index] }} {{ vm.content }}
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="120" align="center" v-if="!type">
                            <template slot-scope="scope">
                                <el-link type="primary" size="mini" :underline="false" class="mr10"
                                         @click="handleDetail(undefined,scope.row)">编辑
                                </el-link>
                                <el-popconfirm title="确定删除？"
                                               @confirm="handleChangeState({id:scope.row.id,isDelete: 1})">
                                    <el-link slot="reference" size="mini" type="danger" :underline="false">删除
                                    </el-link>
                                </el-popconfirm>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </SectionContent>
        </el-main>
        <el-footer style="display: flex;align-items: center;"
                   :style="{justifyContent:type===1?'space-between':'flex-end' }">
            <el-checkbox :checked="checkAll" :value="checkAll" v-if="type" @change="handleCheckAll">全选
            </el-checkbox>
            <el-pagination background layout="prev, pager, next"
                           :current-page="form.pageIndex"
                           :page-size="form.pageSize"
                           :total="result.total"
                           @current-change="getList">
            </el-pagination>
        </el-footer>
        <edit-dialog ref="edit-dialog" @success="handleSaveSuccess"></edit-dialog>
        <import-dialog ref="import-dialog" @success="getList(1)"></import-dialog>
    </div>
</template>

<script>
import comListSearch from "@/www/components/comListSearch/index.vue";
import SectionContent from "@/components/sectionContent/index.vue";
import {getQuestionListApi, updateQuestionApi} from "@/www/urls/driverLearning";
import EditDialog from "@/www/pages/driverManage/driverLearning/question/edit-dialog.vue";
import {getCityData} from "@/urls";
import ImportDialog from "@/www/pages/driverManage/driverLearning/question/ImportDialog.vue";
import {combinationForm} from "@/common/js/common";

const createForm = () => ({
    pageIndex: 1,
    pageSize: 10,
    messageType: undefined,
    messageTitle: undefined
})

export default {
    name: "questionList",
    props: {
        type: Number,
        multipleSelection: Array
    },
    data() {
        return {
            cityList: [],
            loading: false,
            form: createForm(),
            letters: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M'],
            result: {
                data: [],
                total: 0
            },
            showImportDialog: false
        }
    },
    computed: {
        checkAll() {
            return this.result.data.length > 0 && this.result.data.every(({id}) => this.multipleSelection.find((item) => item.id === id))
        }
    },
    components: {
        comListSearch,
        SectionContent,
        EditDialog,
        ImportDialog
    },
    mounted() {
        this.getServerCity();
        if (this.type)
            this.getList()
    },
    activated() {
        if (this.$route.query.t !== this._t) {
            this._t = this.$route.query.t;
            this.getList();
        }
    },
    methods: {
        async getList(page = 1) {
            this.form.pageIndex = page;
            this.loading = true
            const ret = await getQuestionListApi(combinationForm(this.form))
            this.loading = false
            if (ret.success)
                this.result = {
                    data: ret.data.data.map((item) => ({
                        ...item,
                        questionInfo: JSON.parse(item.questionInfo),
                        questionType: item.questionType + ''
                    })),
                    total: ret.data.total
                }
            else this.$message.error(ret.errors[0].message)
            this.$emit('total-change', this.result.total);
            setTimeout(() => {
                if (this.multipleSelection && this.multipleSelection.length) {
                    this.multipleSelection.forEach(({id}) => {
                        const row = this.result.data.find((item) => item.id === id);
                        if (row)
                            this.$refs['tableRef'].toggleRowSelection(row, true)
                    })
                }
            }, 100)
        },
        handleDetail(type, record) {
            this.$refs['edit-dialog'].open(record)
        },
        resetClick() {
            this.form = createForm();
            this.getList(1)
        },
        handleAdd() {
            this.$refs['edit-dialog'].open()
        },
        async handleChangeState(form) {
            this.loading = true;
            const ret = await updateQuestionApi(form)
            if (!ret.success) {
                this.loading = false;
                return this.$message.error(ret.errors[0].message)
            }
            this.getList();
        },
        handleSaveSuccess(record) {
            if (!record.id)
                this.form.pageIndex = 1;
            this.getList(this.form.pageIndex)
        },
        handleSelect(selection, row) {
            const index = this.multipleSelection.findIndex(({id}) => id === row.id);
            if (index !== -1) {
                this.multipleSelection.splice(index, 1)
            } else this.multipleSelection.push(row)
            this.$emit('selection-change', this.multipleSelection)
        },
        handleCheckAll(checked) {
            const list = this.result.data.filter(({id}) => {
                const item = this.multipleSelection.find((item) => item.id === id);
                if (checked) {
                    return !item
                } else return !!item
            })
            if (checked && (this.multipleSelection.length + list.length) > 100) {
                return this.$msgbox.alert('最多可关联100道题')
            }
            if (checked) {
                this.multipleSelection.push(...list)
            } else {
                list.forEach(({id}) => {
                    const index = this.multipleSelection.findIndex((item) => item.id === id)
                    if (index !== -1)
                        this.multipleSelection.splice(index, 1)
                })
            }
            list.forEach((row) => {
                this.$refs['tableRef'].toggleRowSelection(row)
            })
            this.$emit('selection-change', this.multipleSelection)
        },
        selectable(row) {
            return this.multipleSelection.length !== 100 || this.multipleSelection.find(({id}) => id === row.id)
        },
        async getServerCity() {
            try {
                let res = await getCityData(this, '2');
                if (res) {
                    this.cityList = res;
                } else
                    this.cityList = [];

            } catch (e) {
                console.log(e);
            }
        },
        handleImport() {
            this.$refs['import-dialog'].open()
        }
    }
}
</script>

<style scoped lang="scss">
.type-1 {
    height: 100%;

    &::v-deep {
        .el-table__body-wrapper {
            height: calc(100vh - 470px);
            overflow: hidden;
            overflow-y: scroll;
        }
    }
}

.eTrip-section-cont {
    margin-top: 10px;

    &::v-deep {
        .common-list-search {
            padding: 15px 20px;
        }

        .el-table__header-wrapper {
            .el-checkbox {
                display: none;
            }
        }


        .list-search-before {
            .el-form-item {
                margin-bottom: 0;
            }
        }

        .list-search-after {
            width: 440px;
        }
    }
}

.message-info-btn {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
</style>
